import { RichText } from "prismic-reactjs";
import React from "react";
import { isMobile } from "../../../utils";
import { Row, Col, Image } from "react-bootstrap";
import "../../../styles/components/HomePage/DataSections/style.scss";

const DataSections = ({ bannerText, image, separator, width, height }) => {
  return (
    <>
      <Row className="mw-1440 mx-auto data-section">
        <Row className="investment_section">
          <Col md={6} className="pr-20">
            <div className="rich-text">
              <RichText render={bannerText} />
            </div>
          </Col>
          <Col className="data-section-2-img pl-20" md={6}>
            <Image
              src={image}
              alt=""
              fluid
              style={{
                maxWidth: isMobile()
                  ? `${window.screen.width - 40}px`
                  : width > 540
                  ? "540px"
                  : `${width}px`,
                maxHeight: isMobile()
                  ? `${((window.screen.width - 40) * height) / width}px`
                  : width > 540
                  ? `${(540 * height) / width}px`
                  : `${height}px`,
              }}
            />
          </Col>
        </Row>
        {separator && (
          <Col md={12}>
            <hr />
          </Col>
        )}
      </Row>
    </>
  );
};

export default DataSections;
