import React from "react";
import { Image } from "react-bootstrap";

const ServicesImages = ({ heading1, heading2, image, displayPlay }) => {
  return (
    <div className="container-image">
      <Image src={image} alt="" fluid className="service-imgs" />
      <div className="top-left">
        <p
          style={{
            marginBottom: 0,
            textAlign: "left",
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: 300,
            color: "#FFFFFF",
          }}
        >
          {heading1}
        </p>
        <h4 className="text-white">
          <b>{heading2}</b>
        </h4>
      </div>
      <div className="centered">
        {displayPlay && <Image src="/assets/images/play.png" alt="" fluid />}
      </div>
    </div>
  );
};

export default ServicesImages;
