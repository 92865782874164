import { RichText } from "prismic-reactjs";
import React from "react";
import { Image } from "react-bootstrap";
import "../../../styles/components/HomePage/InvestmentExperience/Facilities/style.scss";

const Facilities = ({ bannerText, heading, image, description, showLine }) => {
  return (
    <>
      <div className="facilities">
        <div className="facilities-details">
          <Image src={image} fluid className="facilities-image" />
          <div style={{ maxWidth: 370 }} clz>
            <RichText render={bannerText} />
          </div>
        </div>
        <div className="facilities-separator">
          {showLine && <Image src="/assets/images/Line 4.png" alt="" fluid />}
        </div>
      </div>
    </>
  );
};

export default Facilities;
