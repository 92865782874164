import React, { useEffect, useState } from "react";
import * as prismic from "@prismicio/client";
import Posts from "./Posts";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { client as prismicClient } from "../../../libs/prismic";
import { RichText } from "prismic-reactjs";
import "../../../styles/components/HomePage/Brands/style.scss";

const Brands = ({ heading, description, bannerText }) => {
  const [brandsDoc, setBrandsDoc] = useState();
  const [activeBrand, setActiveBrand] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const listResponse = await prismicClient().get({
          predicates: [
            prismic.predicate.at(
              "my.opal_fund_partners.brand_show_slider",
              true
            ),
            prismic.predicate.at("document.type", "opal_fund_partners"),
          ],
          orderings: {
            field: "document.first_publication_date",
            direction: "desc",
          },
        });

        setBrandsDoc(listResponse?.results || []);
      } catch (error) { }
    }
    fetchData();
  }, []);

  const brandSettingsMobile = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 12000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  const brandSettings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 12000,
    variableWidth: true,
    arrows: false,
    speed: 500,
  };
  return (
    <div className="brands">
      <div className="brands-banner rich-text text-center">
        <RichText render={bannerText} />
      </div>
      <div className="slick-web">
      <Slider
          beforeChange={(_, number) => setActiveBrand(number)}
          {...brandSettings}
        >
          {brandsDoc &&
            brandsDoc
              .filter(
                ({ data: { brand_show, brand_show_slider } }) =>
                  brand_show && brand_show_slider
              )
              ?.reverse()
              ?.map((item, index) => (
                <Posts
                  key={index}
                  active={activeBrand === index}
                  image={item?.data?.brand_image?.url}
                  width={360}
                  heading={item?.data?.brand_heading_1[0]?.text}
                  description={item?.data?.brand_description_1[0]?.text}
                  bottomHeading={item?.data?.brand_amount_card[0]}
                  threeDots={item?.data?.brand_3_dots}
                  navigation={`/brand/${item?.uid}`}
                />
              ))}
        </Slider>
      </div>
      <div className="slick-mobile">
        <Slider {...brandSettingsMobile}>
            {brandsDoc?.map(
              (item, index) =>
                item?.data?.brand_show &&
                item?.data?.brand_show_slider && (
                  <Posts
                    key={index}
                    image={item?.data?.brand_image?.url}
                    width={270}
                    heading={item?.data?.brand_heading_1[0]?.text}
                    description={item?.data?.brand_description_1[0]?.text}
                    bottomHeading={item?.data?.brand_amount_card[0]}
                    threeDots={item?.data?.brand_3_dots}
                    navigation={`/brand/${item?.uid}`}
                  />
                )
            )}
          </Slider>
      </div>
    </div>
  );
};
export default Brands;
