import { navigate } from "gatsby";
import * as prismic from "@prismicio/client";
import { RichText } from "prismic-reactjs";
import { isMobile } from "../../../utils";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import Loader from "../../Loader";
import { client as prismicClient } from "../../../libs/prismic";
import "../../../styles/components/Blog/MainBlog/style.scss";

const MainBlog = ({ bannerText }) => {
  const [blogListDoc, setBlogListDoc] = useState();

  const handleNavigate = (item) => {
    navigate(`/blog/${item.uid}`);
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const listResponse = await prismicClient().get({
          predicates: [
            prismic.predicate.at("my.blog_post.featured_on_home_page", true),
            prismic.predicate.at("document.type", "blog_post"),
          ],
          orderings: {
            field: "document.first_publication_date",
            direction: "desc",
          },
          pageSize: 2,
        });
        listResponse?.results.forEach((item) => {
          item.dateTimestamp = new Date(item?.data?.date).getTime();
          for (let element of item?.data?.body) {
            if (element.slice_type === "image") {
              item.featureImage = element?.primary?.image?.url;
              break;
            }
          }
        });
        setBlogListDoc(listResponse?.results || []);
      } catch (error) {}
    }
    fetchData();
  }, []);
  return blogListDoc ? (
    <Row className="main-blog">
      <Col md={12}>
        <Container>
          <div className="blog_banner rich-text text-center">
            <RichText render={bannerText} />
          </div>
          <Row>
            {blogListDoc.map((item) => {
              return (
                <>
                  <Col md={6}>
                    <Row className="blog_items">
                      <Col md={12}>
                        {item?.featureImage && (
                          <Image
                            src={item.featureImage}
                            className="blog-image img-fluid"
                          />
                        )}
                      </Col>
                      <Col md={12}>
                        <div className="blog-content">
                          <h5
                            className={clsx("blog-title", {
                              "mt-0": !item?.featureImage,
                            })}
                          >
                            <b>{item?.data?.title[0]?.text || ""}</b>
                          </h5>
                          <p className="blog-description text-center">
                            {item?.data?.excerpt[0]?.text || ""}
                          </p>
                          {item?.uid && (
                            <a
                              onClick={() => handleNavigate(item)}
                              className="blog-learn-more text-center"
                            >
                              Read more
                            </a>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {isMobile() && (
                    <Col md={12} className="blog_separator">
                      <hr />
                    </Col>
                  )}
                </>
              );
            })}
            {!isMobile() && (
              <Col md={12} className="blog_separator">
                <hr />
              </Col>
            )}
          </Row>
        </Container>
      </Col>
    </Row>
  ) : (
    <Loader />
  );
};

export default MainBlog;
