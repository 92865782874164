import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { moduleTypes } from "../store/type";
import Layout from "../components/Layout";
import Banner from "../components/HomePage/Banner";
import DataSections from "../components/HomePage/DataSections";
import Brands from "../components/HomePage/Brands";
import InvestmentExperience from "../components/HomePage/InvestmentExperience";
import MainBlog from "../components/Blog/MainBlog";
import Hero from "../components/Hero";
import PreFooter from "../components/PreFooter";
import { client as prismicClient } from "../libs/prismic";
import Loader from "../components/Loader";
import { getProfile } from "../utils/auth";
import {
  hideAlert,
  showAlert,
  NON_ACCREDITED_BANNERS,
  INITIAL_QUALIFICATION,
  SUBSEQUENT_LOG_INS,
} from "../store/reducers/alert";
import { navigate } from "gatsby";

const IndexPage = ({ accounts, activeStep, firstTimeLogin, disqualificationOfQualified, disqualificationOfAccredited }) => {
  const user = getProfile();
  const [homeDoc, setHomeDoc] = useState();
  const [isMacOS, setIsMacOS] = useState(false);
  const dispatch = useDispatch();

  const loading = useSelector(
    (state) => state?.app?.loading
  );
  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle("home");
      setHomeDoc(response);
    }

    fetchData();

    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsMacOS(/mac/.test(userAgent));
  }, []);


  useEffect(() => {
    let alertType;

    if (!user) {
      return
    }
    if (accounts.parentAccounts?.[0]?.type === 'ria' && accounts.parentAccounts?.[0]?.verification === 'approved') {
      navigate('/invest-trade/?from=home')
    }
    if (disqualificationOfQualified === undefined && disqualificationOfAccredited === undefined) {
      dispatch(hideAlert());
      return
    }

    if (disqualificationOfAccredited && accounts.parentAccounts?.length > 0 && accounts.newAccount?._id !== accounts.parentAccounts[0]?._id) {
      dispatch(hideAlert());
      navigate('/accounts/');
    }

    if (disqualificationOfAccredited && accounts.parentAccounts?.length > 1 && [
      "qualification-individual-one",
      "qualification-business-one",
      "qualification-trust-one",
    ].includes(activeStep)) {
      dispatch(hideAlert());
      navigate('/accounts/');
    }

    switch (activeStep) {
      case "qualification-individual-one":
      case "qualification-business-one":
      case "qualification-trust-one":
        alertType = { type: NON_ACCREDITED_BANNERS };
        break;
      default:
        dispatch(hideAlert());
    }

    if (alertType) {
      dispatch(showAlert({
        ...alertType,
        subType: firstTimeLogin ? INITIAL_QUALIFICATION : SUBSEQUENT_LOG_INS
      }));
    }

    if (accounts.parentAccounts?.length > 1 && [
      "qualification-individual-one",
      "qualification-business-one",
      "qualification-trust-one",
    ].includes(activeStep)) {
      dispatch(hideAlert());
      return;
    }

    if (accounts.parentAccounts?.length > 1 || ![
      "investor-type",
      "individual-type",
      "investor-type-ria",
      "investor-type-individual",
      "investor-type-joint",
      "investor-type-sd-ira",
      "investor-type-entity",
      "investor-type-business",
      "qualification-individual-one",
      "qualification-business-one",
      "qualification-trust-one",
    ].includes(activeStep)) {
      navigate('/invest-trade/?from=home')
    }

  }, [accounts, activeStep, firstTimeLogin, dispatch, user, disqualificationOfQualified, disqualificationOfAccredited])

  return (homeDoc && !loading) ? (
    <Layout>
      <Hero />
      <Banner
        bannerText={homeDoc.data?.section_1}
        bannerTextLine1={homeDoc.data?.section_1_line_1}
        bannerTextLine2={homeDoc.data?.section_1_line_2}
        bannerTextLine3={homeDoc.data?.section_1_line_3}
        showButton={homeDoc.data?.section_1_show_button}
        buttonText={homeDoc.data?.section_1_button_text[0]?.text}
        buttonLink={homeDoc.data?.section_1_button_link?.url}
        showImage={homeDoc.data?.section_1_show_image}
        imgSRC={homeDoc.data?.section_1_image}
        bgImgSRC={isMacOS ? homeDoc.data?.section_1_mac_background_color_image : homeDoc.data?.section_1_background_color_image}
        splashMovie={homeDoc.data?.section_1_background_splash_movie}
        splashMovieMobile={homeDoc.data?.section_1_background_splash_movie_mobile}
      />
      <DataSections
        bannerText={homeDoc.data?.section_2}
        image={homeDoc.data?.section_2_image?.url}
        separator={true}
        width={homeDoc.data?.section_2_image?.dimensions?.width}
        height={homeDoc.data?.section_2_image?.dimensions?.height}
      />
      <DataSections
        bannerText={homeDoc.data?.section_3}
        image={homeDoc.data?.section_3_image?.url}
        separator={false}
        width={homeDoc.data?.section_3_image?.dimensions?.width}
        height={homeDoc.data?.section_3_image?.dimensions?.height}
      />
      <Brands bannerText={homeDoc.data?.brand_section} />
      <InvestmentExperience
        bannerText={homeDoc.data?.investment_section}
        serviceImages={homeDoc.data?.investment_services}
        facilities={homeDoc.data?.investment_facilities}
      />
      <MainBlog bannerText={homeDoc.data?.blog_section} />
      <PreFooter
        className="home-pre-footer"
        prefooterText={homeDoc.data?.prefooter}
        buttonText={homeDoc.data?.prefooter_button_text[0]?.text}
        buttonLink={homeDoc.data?.prefooter_button_link?.url}
      />
    </Layout>
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  activeStep: state[moduleTypes.INVESTOR_QUALIFICATION].activeStep,
  accounts: state.accounts,
  firstTimeLogin: state?.[moduleTypes.APP]?.profile?.firstTimeLogin,
  disqualificationOfAccredited: state[moduleTypes.INVESTOR_QUALIFICATION].disqualificationOfAccredited,
  disqualificationOfQualified: state[moduleTypes.INVESTOR_QUALIFICATION].disqualificationOfQualified,
})

export default connect(mapStateToProps)(IndexPage);
