import React from "react";
import { navigate } from "gatsby";
import { isMobile } from "../../../utils";
import "../../../styles/components/HomePage/Brands/Posts/style.scss";

function add3Dots(string, limit) {
  var dots = "...";
  if (string.length > limit) {
    string = string.substring(0, limit) + dots;
  }
  return string;
}

const Posts = ({
  image,
  width,
  heading,
  description,
  bottomHeading,
  bottomHeadingBold,
  handleBrands,
  navigation,
  threeDots
}) => {
  const handleNavigate = () => {
    navigate(navigation);
  };
  return (
    <div className="post" onClick={handleNavigate}>
      <img
        src={image}
        alt=""
        style={{ maxHeight: 60, minHeight: 60, maxWidth: width, marginLeft: 190 }}
      />
      <div>
        <h3 className="post-details">{heading}</h3>
        <p className="post-subDetails">
          {description && threeDots
            ? add3Dots(description, threeDots)
            : description}
        </p>
        {/* </div> */}
        <div className="post-separator"> </div>
        {bottomHeading && bottomHeading?.spans?.length ? (
          <h5 className="text-black">
            {bottomHeading &&
              bottomHeading.text.substring(
                0,
                bottomHeading && bottomHeading.spans[0].start
              )}
            <b className={isMobile() ? "text-blue-dark": "text-gray-900"}>
              {bottomHeading &&
                bottomHeading.text.substring(
                  bottomHeading.spans[0].start,
                  bottomHeading.spans[0].end
                )}
            </b>
            {bottomHeading &&
              bottomHeading.text.substring(
                bottomHeading && bottomHeading.spans[0].end
              )}
          </h5>
        ) : (
          <h5 className="text-black">{bottomHeading.text}</h5>
        )}
      </div>
    </div>
  );
};
export default Posts;
