import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ServicesImages from "./ServicesImages";
import Facilities from "./Facilities";
import { RichText } from "prismic-reactjs";
import { isMobile } from "../../../utils";
import "../../../styles/components/HomePage/InvestmentExperience/style.scss";

const InvestmentExperience = ({
  bannerText,
  serviceImages,
  facilities,
}) => {
  return (
    <div className="investment">
      <div className="investment-banner rich-text text-center px-2">
        <RichText render={bannerText} />
      </div>
      <Container fluid className="investment-container p-0">
        <Row className="service-img-container justify-content-center mw-1440 mx-auto">
          {
            serviceImages &&
            serviceImages.map((item, index) => (
              <Col key={index} md={6} sm={12}>
                <ServicesImages
                  heading1={item.investment_service_heading_1[0].text}
                  heading2={item.investment_service_heading_2[0].text}
                  image={
                    isMobile()
                      ? item.investment_service_image_mobile.url
                      : item.investment_service_image.url
                  }
                  displayPlay={item.investment_service_display_play}
                />
              </Col>
            ))
          }
        </Row >
        <Row className="service-setting mw-1440 mx-auto">
          {facilities &&
            facilities.map((item, index) => (
              <Col md={4} key={index}>
                <Facilities
                  bannerText={item.investment_facility_section}
                  image={item.investment_facility_image.url}
                  showLine={item.investment_facility_show_line}
                />
              </Col>
            ))}
        </Row>
      </Container >
    </div >
  );
};

export default InvestmentExperience;
