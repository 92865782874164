import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { useTrail, animated } from 'react-spring';
import { Row, Col, Image, Button } from "react-bootstrap";
import { RichText } from "prismic-reactjs";
import { signup } from "../../../utils/auth";
import "../../../styles/components/HomePage/Banner/style.scss"
import { navigate } from "gatsby";
import {
  showAlert,
} from "../../../store/reducers/alert";
import { moduleTypes } from "../../../store/type";
import { isMobile } from "../../../utils";

const springConfig = { tension: 2000, friction: 200, mass: 5, duration: 1200 };

const Banner = ({
  bannerText,
  bannerTextLine1,
  bannerTextLine2,
  bannerTextLine3,
  showButton,
  buttonText,
  showImage,
  imgSRC,
  bgImgSRC,
  isLoggedIn,
  disqualificationOfAccredited,
  disqualificationOfQualified,
  splashMovie,
  splashMovieMobile
}) => {
  const movieRef = useRef(null);
  const mobileMovieRef = useRef(null);
  const [videoSource,] = useState(splashMovie);
  const [mobileVideoSource,] = useState(splashMovieMobile);
  const [bgImg, setBgImg] = useState(bgImgSRC);
  const [bgStyle, setBgStyle] = useState("");
  const [mobileDevice, setMobileDevice] = useState(window?.innerWidth < 768 || false);
  const [autoPlayAvailable, setAutoPlayAvailable] = useState(true);
  const bannerTextGroup = [ bannerTextLine1, bannerTextLine2, bannerTextLine3 ];
  const trail = useTrail( bannerTextGroup.length, {
    config: springConfig,
    to: { opacity: 1 },
    from: { opacity: 0 }
  } );

  const handleNavigate = () => {
    if (isLoggedIn === true) {
      if (!disqualificationOfAccredited || !disqualificationOfQualified)
        navigate('/investor-qualification/')
    } else {
      navigate('/signup')
    }
  };

  useLayoutEffect( () => {
    function update() {
      setMobileDevice(window.innerWidth < 768 || isMobile())
    }

    if (!window) {
      return;
    }

    window.addEventListener('resize', update)
    return () => window.removeEventListener('resize', update);
  })

  useEffect( () => {
    const {current:ref} = movieRef || {}
    if(mobileDevice || !ref) return

    let timer = setTimeout(()=>{
      setAutoPlayAvailable(false);
    },1000)

    // start movie
    ref.play()
      .then (() => {
        setAutoPlayAvailable(true)
      }).catch (e => {
        setAutoPlayAvailable(false)
      }).finally(() => {
        clearTimeout(timer);
      });
  },[movieRef, mobileDevice])

  useEffect( () => {
    const {current:ref} = mobileMovieRef || {}
    if(!mobileDevice || !ref) return

    let timer = setTimeout(()=>{
      setAutoPlayAvailable(false);
    },1000)

    // start movie
    ref.play()
      .then (() => {
        setAutoPlayAvailable(true)
      }).catch (e => {
        setAutoPlayAvailable(false)
      }).finally(() => {
        clearTimeout(timer);
      });
  },[mobileMovieRef, mobileDevice]);

  useEffect( () => {
    const img = mobileDevice ? bgImgSRC.mobile : bgImgSRC
    setBgImg(img);

    setBgStyle( mobileDevice 
      ? { background: `url(${img?.url?.replace(/[?].*/,'')}) center top / auto ${img?.dimensions?.height + 60}px repeat-x` }
      : { background: `url(${img?.url?.replace(/[?].*/,'')}) center top / auto` },
    );
  },[mobileDevice])
  
  return (
    <div className="homepage-container" style={{
      ...bgStyle,
      height: `${bgImg?.dimensions?.height}px`}}>
      <Row id="homepage_banner" className="homepage mx-auto mw-1440" style={{minHeight: `${bgImg?.dimensions?.height}px`}}>

        <div className={`${mobileDevice ? "hidden" : ""} background-image`} style={{minWidth: `${imgSRC?.dimensions?.width}px`, minHeight: `${imgSRC?.dimensions?.height}px`}}>
          <video ref={movieRef} className={`${!autoPlayAvailable ? "hidden" : ""}`}
            muted playsInline>
              <source src={videoSource?.url} />
          </video>
          
          <div className={`${autoPlayAvailable ? "hidden" : ""}`}>
            {showImage && <Image className="screen-lg" src={imgSRC?.url} alt={imgSRC?.alt} fluid 
                    style={{minWidth: `${imgSRC?.dimensions?.width}px`, height: `${imgSRC?.dimensions?.height}px`}}/>}
          </div>
        </div>
        <Row className="mt-home">
          <Col className="my-auto" md={6}>
            <div className="banner rich-text home-rich-text mt-0">
              { bannerText && <RichText render={bannerText} /> }
              { bannerTextLine1 && ( 
                  trail.map( (style,index) => <animated.div key={index} style={style}>
                    <RichText render={bannerTextGroup[index]} />
                  </animated.div> )
                )
              }
              {showButton && (
                <Button
                  variant="dark"
                  className="get-started-btn"
                  onClick={handleNavigate}
                >
                  <b>{buttonText}</b>
                </Button>
              )}
            </div>
          </Col>
          <Col className="banner-img m-0 pl-20" md={6}>
            <video ref={mobileMovieRef} className={`${!autoPlayAvailable ? "hidden" : ""} screen-sm`} muted playsInline style={{width: '100vw', minWidth: `${imgSRC?.mobile?.dimensions?.width}px`, maxWidth: `${imgSRC?.mobile?.dimensions?.width+80}px` }}>
                <source src={mobileVideoSource?.url} />
            </video>

            {showImage && <Image className={`${autoPlayAvailable ? "hidden" : ""} screen-sm`} src={imgSRC?.mobile?.url} alt={imgSRC?.mobile?.alt} fluid style={{width: '100vw', minWidth: `${imgSRC?.mobile?.dimensions?.width}px`, maxWidth: `${imgSRC?.mobile?.dimensions?.width+80}px` }}/>}
          </Col>
        </Row>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    activeStep: state[moduleTypes.INVESTOR_QUALIFICATION].activeStep,
    disqualificationOfAccredited: state[moduleTypes.INVESTOR_QUALIFICATION].disqualificationOfAccredited,
    disqualificationOfQualified: state[moduleTypes.INVESTOR_QUALIFICATION].disqualificationOfQualified,
    isLoggedIn: state[moduleTypes.APP].isLoggedIn,
  }
}

function mapDispatchToProps (dispatch) {
  return {
    showAlert: (...rest) => {
      dispatch(showAlert(...rest))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
